<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
<template>
    <div class="dmc-table-wrap">
        <div class="list_box" v-if="tabletype !== '4'">
            <div class="list_content" v-for="(item, i) in tableData" :key="i">
                <div class="title_box">
                    <div class="title_left">
                        <span class="iconfont icondot dot" :class="{active: item.flag}"></span>
                        <h2>{{ item.title }}</h2>
                        <el-tooltip
                            v-if="item.flag"
                            class="item"
                            effect="dark"
                            content="取消收藏"
                            placement="top"
                        >
                            <span class="el-icon-star-on collect" style="color: #FFA500;"></span>
                        </el-tooltip>
                        <el-tooltip
                            v-else
                            class="item"
                            effect="dark"
                            content="收藏"
                            placement="top"
                        >
                            <span class="el-icon-star-on collect" style="color: #D7D7D7;"></span>
                        </el-tooltip>
                    </div>
                    <div class="title_right">
                        <span class="date">发布时间：2023-12-01 11:00:00</span>
                        <span v-if="!item.flag" class="btn">标为已读</span>
                        <span v-else class="btn read">已读</span>
                    </div>
                </div>
                <div class="msg msg_name">
                    消息发送人：{{ item.name }}
                </div>
                <div v-if="tabletype !== '1'" class="msg msg_hint">
                    您有一条<span class="hint_title">【{{ item.title }}】</span>业务待处理，请前往处理！
                </div>
                <div v-else class="msg msg_hint">
                    【天然砂(河砂)河砂华东】库存xxxx吨，请及时处理！
                </div>
            </div>
        </div>
        <div class="list_box" v-else>
            <div class="list_content" v-for="(item, i) in tableData" :key="i">
                <div class="title_box">
                    <div class="title_left">
                        <span class="iconfont icondot dot" :class="{active: item.flag}"></span>
                        <h2>{{ item.title }}</h2>
                    </div>
                    <div class="title_right">
                        <span>发布时间：2023-12-01 11:00:00</span>
                    </div>
                </div>
                <div class="msg msg_hint" v-for="(dat, j) in item.list || []" :key="j">
                    {{ j + 1 }}、{{ dat }}
                </div>
                <div class="btn_box">
                    <span class="btn detail" @click="openDetail(item)">查看详情</span>
                    <span v-if="!item.flag" class="btn">标为已读</span>
                    <span v-else class="btn read">已读</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
export default {
    props: {
        // tableData: Array, // 表格数据
        tabletype: String,
        columns: Array, // 表头
        loading: Boolean, // 是否加表格loading
        customStyle: String, // 自定义样式
        pagination: {
            type: Object,
            default: null,
        }, // 分页
        operateColumn: {
            type: Object,
        }, // 操作集合
        otherHeight: {
            type: Number,
            default: 170,
        }, // 表格之外的高度
        border: {
            type: Boolean,
            default: true,
        },
        pageSize: {
            type: Number,
            default: 10,
        }, // 每页数量
        showPagination: {
            type: Boolean,
            default: true,
        }, // 是否显示分页
        tableRowClassName: Function,
    },
    components: {
        NodeContent: {
            props: {
                column: {
                    required: true,
                },
                row: {
                    required: true,
                },
            },
            render(h) {
                return h('div', {}, [this.column.render(this.row, this.column)]);
            },
        },
    },
    data() {
        return {
            tablePagination: {
                current: 1,
                pageSize: this.pagination && this.pagination.size || this.pageSize,
            },
            sort: undefined,
            highlight: true,
            tableData: [
                {
                    title: '合同审批-正式',
                    flag: true,
                    name: '李佳佳',
                    list: [
                        '将与生产服务中相关的GPS坐标消息处理模块迁移。',
                        '将与生产服务中相关的GPS坐标消息处理模块迁移。',
                        '将与生产服务中相关的GPS坐标消息处理模块迁移。',
                        '将与生产服务中相关的GPS坐标消息处理模块迁移。',
                    ],
                },
                {
                    title: '合同审批-正式',
                    flag: true,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: false,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: false,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: true,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: false,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: true,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: true,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: false,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: true,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: true,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: true,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: true,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: true,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: true,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: true,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: true,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: true,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: true,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: true,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: true,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: true,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: true,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: true,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: true,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: true,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: true,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: true,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: true,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: true,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: true,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: true,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: true,
                    name: '李佳佳',
                },
                {
                    title: '合同审批-正式',
                    flag: true,
                    name: '李佳佳',
                },
            ],
        };
    },
    computed: {
        height() {
            return this.getWidthHeight().height - this.otherHeight;
        },
        computedPagination() {
            if (this.pagination) {
                return this.pagination;
            }
            return {
                size: this.pageSize,
                total: this.tableData ? this.tableData.length : 0,
            };
        },
        computedTableData() {
            if (!this.showPagination) {
                return this.tableData;
            }
            if (this.pagination) {
                return this.tableData;
            }
            if (this.tableData) {
                return this.tableData.slice((this.tablePagination.current - 1) * this.pageSize, this.tablePagination.current * this.pageSize);
            }
            return null;
        },
    },
    methods: {
        openDetail(item) {
            this.$emit('openDetail', item);
        },
        handleCurrentChange(current, emit = true) {
            document.getElementsByClassName('el-table__body-wrapper')[0].scrollTop = 0;
            if (this.pagination) {
                this.tablePagination = {
                    current,
                    pageSize: this.pagination.size,
                };
                if (emit) {
                    this.$emit('handleTableChange', this.tablePagination, this.sort);
                }
            } else {
                this.tablePagination = {
                    current,
                    pageSize: this.pageSize,
                };
            }
        },
        sortChange(sort) {
            document.getElementsByClassName('el-table__body-wrapper')[0].scrollTop = 0;
            if (sort.prop) {
                this.sort = {
                    prop: sort.prop,
                    order: sort.order,
                };
            } else {
                this.sort = undefined;
            }
            this.$emit('handleTableChange', this.tablePagination, this.sort);
        },
        rowClick(row, column, event) {
            this.$emit('handleRowClick', row);
        },
        readAll() {
            this.$confirm('确定将您所有未读消息置为已读吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$emit('readAllClick');
            }).catch(() => {         
            });
            
        },
        getWidthHeight() {
            let w = window, d = document, e = d.documentElement, g = d.getElementsByTagName('body')[0], x = w.innerWidth || e.clientWidth || g.clientWidth, y = w.innerHeight || e.clientHeight || g.clientHeight
            return {width: x, height: y}
        },
    },
};
</script>
<style lang="stylus">
.dmc-table-wrap
    height 100%
    .list_box
        height 100%
        overflow-y auto
        .list_content
            padding .1rem
            min-height 1.6rem
            background #fff
            border 1px solid #DBE6F1
            margin-top .1rem
            div
                height .42rem
                line-height .42rem
            .msg
                margin-left .2rem
            .msg_name
                color #666666
                font-size .16rem
            .msg_hint
                font-size .18rem
                .hint_title
                    color #47a2ff
            .title_box
                display flex
                .title_left
                    flex 1
                    .dot
                        font-size .18rem
                        color #C2C2C2
                        &.active
                            color #EE3A3A
                    h2
                        display inline-block
                        font-size .18rem
                        color #1C1F23
                        font-weight 600
                    .collect
                        cursor pointer
                        margin-left .1rem
                .title_right
                    display: flex;
                    justify-content: center;
                    align-items: center;
            .btn_box
                .btn
                    display: inline-block;
                    height .35rem !important
                    line-height .35rem !important
            .btn
                height .28rem
                line-height .28rem
                vertical-align top
                font-size .16rem
                color #47a2ff
                margin 0 .10rem
                cursor pointer
                border 1px solid #47a2ff
                padding: 0 .1rem;
                border-radius 3px
            .detail
                color #34495E !important
                background #F0F0F0 !important
                border none !important
            .read
                color #C2C2C2 !important
                border 1px solid #C2C2C2 !important









.pagination {
    float: right;
    margin: 20px;
}

.el-table-column--selection .cell {
    padding-left: 0;
}
.iconicon-starfilled,.iconicon-star{
    font-size:.24rem;
    cursor: pointer;
    padding-left: 10px;
    color: #f48c18;
}
.readed{
    color: #979ea7;
}
.unread{
    color: #f55244;
}   
.read-btn{
    width: .70rem;
    height: .25rem;
    border-radius: .04rem;
    border: 1px solid #5588F1;
    color: #5588F1;
    font-size: .12rem;
    font-weight: normal;
    display: inline-block;
    text-align: center;
    line-height: .23rem;
    cursor: pointer;
}    
</style>
